<template>
  <div class="courseUser"> 
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">数据统计</a>
                <i>></i>
                <a href="javascript:;" @click="init" class="cur-a">发布课程使用</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl " style="display:flex;flex-direction: column;align-items: flex-start">
                    <div class="searchbox" style="margin-bottom:15px">
                        <div title="课程名称" class="searchboxItem ci-full">
                            <span class="itemLabel">课程名称:</span>
                            <el-input v-model="selectData.courseName" type="text" size="small" placeholder="请输入课程名称" clearable />
                        </div>
                        <div title="使用区域" class="searchboxItem ci-full">
                            <span class="itemLabel">使用区域:</span>
                            <el-cascader
                            v-model="selectData.areaId"
                            :options="areatreeList"
                            :props="propsarea"
                            :disabled="disabled"
                            clearable
                            filterable
                            size="small"
                            ></el-cascader>
                        </div>
                        <div title="班级编码" class="searchboxItem ci-full">
                            <span class="itemLabel" style="min-width:6rem">班级编码:</span>
                            <el-input
                                    v-model="selectData.projectCode"
                                    type="text"
                                    size="small"
                                    clearable
                                    placeholder="请输入班级编码"
                            />
                        </div>
                    </div>
                     <div class="searchbox">
                        <div title="使用时间:" class="searchboxItem ci-full">
                            <span class="itemLabel">使用时间:</span>
                            <el-date-picker
                            clearable
                            size="small"
                            v-model="userTime"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </div>
                        <div class="df">
                            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
                            <el-button type="primary" class="bgc-bv" round @click="doExport()">导出</el-button>
                        </div>
                    </div>
                </div>
                 <div class="framePage-scroll">
                    <div class="ovy-a">

                      <el-table
                        ref="multipleTable"
                        :data="tableData"
                        :height="tableHeight"
                        size="small"
                        tooltip-effect="dark"
                        style="width: 100%"
                        :header-cell-style="tableHeader"
                        stripe
                      >
                        <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
                          <el-table-column label="课程名称" align="left" show-overflow-tooltip prop="courseName" min-width="200"/>
                        <el-table-column label="课程使用学时" align="right" show-overflow-tooltip prop="lessonNum" />
                        <el-table-column label="使用区域" align="left" show-overflow-tooltip prop="areaNamePath" min-width="180"/>
                          <el-table-column label="班级编码" align="left" prop="projectCode" show-overflow-tooltip width="180" />
                          <el-table-column label="使用开始时间" align="left" show-overflow-tooltip prop="startDate">
                              <template slot-scope="scope">
                                <span>{{scope.row.startDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="使用结束时间" align="left" show-overflow-tooltip prop="endDate">
                              <template slot-scope="scope">
                                <span>{{scope.row.endDate|momentDate}}</span>
                              </template>
                            </el-table-column>
                            <el-table-column label="完成人数" align="right" show-overflow-tooltip prop="completeCount" />
                        <!-- <el-table-column label="操作" width="100px" align="center" >
                          <template slot-scope="scope">
                            <el-button
                              type="text"
                              size="mini"
                              style="padding:0 15px"
                              @click="goDetail(scope.row.projectId,scope.row.buyId)"
                            >使用详情</el-button>
                          </template>
                        </el-table-column> -->
                        <Empty slot="empty" />
                      </el-table>
                    </div>
                  </div>
                  <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "set/courseUser",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  props: ["name"],
  data() {
    return {
      selectData:{
        areaId: "",
        courseName: "",
        endDate: "",
        startDate: "",
        projectCode: ""
      },
      userTime:"",
      areatreeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
    };
  },
  computed: {},
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getTableHeight();
      this.getareatree();
      this.getData();
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    getData(pageNum) {
      if(this.userTime&&this.userTime.length>0){
        this.selectData.startDate=this.userTime[0];
         this.selectData.endDate=this.userTime[1];
      }else{
         this.selectData.startDate="";
         this.selectData.endDate="";
      }
      const params = {
        ...this.selectData,
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      this.doFetch({
        url: "/biz/buy/stat/courseUse",
        params,
        pageNum
      });
    },
    goDetail(projectId,buyId) {
      this.$router.push({
        path: "/web/set/courseUserDetail",
        query: {
          projectId,
          buyId
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doExport() {
        const params = {
            ...this.selectData
        };
        this.$post("/biz/buy/stat/courseUseExport", params).then(res => {
            if (res.status === "0") {
                window.open(res.data);
            }
        });
    }
  },
  mounted: function() {}
};
</script>

<style lang="less" scope>
.courseUser{
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  >.cell {
    text-align: left;
  }
}
.el-table th.is-right {
  >.cell {
    text-align: right;
  }
}
</style>
